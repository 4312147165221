<script setup>
import loginBackground from '@/assets/images/login-bg.jpeg'
import logo from '@/assets/images/avrillo-logo.png'

const { currentEnvironemnt, isProduction } = useEnvironment()
</script>

<template>
  <InfoBanner v-if="!isProduction" class="fixed inset-x-0 top-0 z-50" :content="currentEnvironemnt + ' Environment'" />
  <main class="flex h-screen w-full items-center justify-center">
    <div class="flex h-[32rem] w-full max-w-4xl overflow-hidden rounded-lg bg-white shadow-lg">
      <div class="hidden flex-1 md:block">
        <img :src="loginBackground" alt="background" class="object-none object-center" />
      </div>

      <div class="flex flex-1 flex-col justify-center p-6">
        <img :src="logo" alt="Logo" class="mx-auto mb-6 block h-12" />
        <slot />
      </div>
    </div>
  </main>
</template>
